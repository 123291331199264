import fontBold from "~/public/fonts/Matter-Bold.otf";
import fontBoldItalic from "~/public/fonts/Matter-BoldItalic.otf";
import fontHeavy from "~/public/fonts/Matter-Heavy.otf";
import fontHeavyItalic from "~/public/fonts/Matter-HeavyItalic.otf";
import fontLight from "~/public/fonts/Matter-Light.otf";
import fontLightItalic from "~/public/fonts/Matter-LightItalic.otf";
import fontMedium from "~/public/fonts/Matter-Medium.otf";
import fontMediumItalic from "~/public/fonts/Matter-MediumItalic.otf";
import fontRegular from "~/public/fonts/Matter-Regular.otf";
import fontRegularItalic from "~/public/fonts/Matter-RegularItalic.otf";
import fontSemiBold from "~/public/fonts/Matter-SemiBold.otf";
import fontSemiBoldItalic from "~/public/fonts/Matter-SemiBoldItalic.otf";

const fontFamily = "Matter";

export {
  fontBold,
  fontMedium,
  fontBoldItalic,
  fontHeavy,
  fontHeavyItalic,
  fontLight,
  fontLightItalic,
  fontMediumItalic,
  fontRegular,
  fontRegularItalic,
  fontSemiBold,
  fontSemiBoldItalic,
  fontFamily,
};
